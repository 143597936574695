.loading-skeleton {
  display: flex;
  flex-direction: column;
}

.loading-skeleton-item {
  height: 20px; /* Set the height of each skeleton item */
  width: 100%;
  background-color: #f0f0f0; /* Set the background color */
  margin-bottom: 10px; /* Adjust spacing between skeleton items */
  border-radius: 4px; /* Optional: Add some border radius */
  animation: loading-animation 1.5s infinite ease-in-out; /* Add animation */
}

/* Keyframes for the loading animation */
@keyframes loading-animation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
