@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

h2 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    font-family: "Inter", serif;
}

h3 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    font-family: "Inter", serif;
}

h4 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: "Inter", serif;
}

p {
    font-family: "Inter", serif;
}

body {
    font-family: "Inter", serif;
}

.dashboard-card {
    border: 1px solid hsla(220, 20%, 80%, 0.4);
    padding: 16px 16px 0;
    background-color: white;
    border-radius: 8px;
}

.border {
    border: 1px solid hsla(220, 20%, 80%, 0.4);
}

.dashboard-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.plus {
    color: hsl(120, 59%, 30%);
    border: 1px solid hsl(120, 75%, 87%);
    background-color: hsl(120, 80%, 98%);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 80px;
    font-size: 12px;
    font-weight: 600;
}

.minus {
    color: hsl(0, 90%, 30%);
    border: 1px solid hsl(0, 92%, 90%);
    background-color: hsl(0, 100%, 97%);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 80px;
    font-size: 12px;
    font-weight: 600;
}

.average {
    color: hsl(220, 20%, 35%);
    border: 1px solid hsl(220, 20%, 88%);
    background-color: hsl(220, 30%, 94%);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 80px;
    font-size: 12px;
    font-weight: 600;
}

.twelve {
    font-size: 12px;
}

table thead tr th {
    padding: 10px 12px;
    border-bottom: 1px solid hsla(220, 20%, 80%, 0.4);
    font-size: 13px;
    font-weight: 500;
    color: #000 !important;
    white-space: nowrap;
    background-color: #f4f6f9;
}

table tbody tr td {
    padding: 15px 12px;
    border-bottom: 1px solid hsla(220, 20%, 80%, 0.4);
    font-size: 14px;
    color: #000;
}

.hover-row:hover {
    background-color: rgba(241, 241, 241, 0.26);
    cursor: pointer;
}

.third-party-connections table td {
    border: none !important;
    padding: 10px 0px;
}

.reporting-dashboard .form-select {
    font-size: 0.9rem !important;
}